import { useState } from 'react';

import useMediaQuery from '@src/hooks/useMediaQuery';
import { WORDPRESS_BASE_URL } from '@src/lib/constants';
import { UAE_Flag_Icon } from '@src/lib/imgUrl';

import {
  CloseBannerButton,
  DesktopBannerText,
  FAQLink,
  MobileBannerText,
  PromoTab,
  StyledBanner,
  StyledPromoBannerImg,
  StyledPromoCode,
  Tooltip,
} from './styles';

const PromotionalBanner = () => {
  const [isPromoBannerVisible, setIsPromoBannerVisible] = useState(true);
  const [isStickerVisible, setIsStickerVisible] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  const isDesktop = useMediaQuery(800);

  // * Set Promo Code here
  const promoCode = 'BLASTOFF15';
  const discountPercentage = 15;

  const copyPromoCode = () => {
    navigator.clipboard.writeText(promoCode);
    setShowTooltip(true);

    setTimeout(() => {
      setShowTooltip(false);
    }, 3000);
  };

  const handleCloseBanner = () => {
    setIsPromoBannerVisible(false);
    setIsStickerVisible(true);
  };

  const handleStickerClick = () => {
    setIsPromoBannerVisible(true);
    setIsStickerVisible(false);
  };

  return (
    <>
      {isPromoBannerVisible && (
        <StyledBanner>
          <CloseBannerButton onClick={handleCloseBanner}>×</CloseBannerButton>
          {isDesktop ? (
            <DesktopBannerText>
              Save {discountPercentage}% on your catering orders with promo code{' '}
              <StyledPromoCode onClick={copyPromoCode}>
                {promoCode} {showTooltip && <Tooltip>Copied!</Tooltip>}{' '}
              </StyledPromoCode>
              Don't miss this online exclusive offer!{' '}
              <FAQLink target={`_blank`} href={`${WORDPRESS_BASE_URL}/faqs`}>
                See our FAQs
              </FAQLink>{' '}
              for details.
            </DesktopBannerText>
          ) : (
            <MobileBannerText>
              Save {discountPercentage}% with promo code{' '}
              <StyledPromoCode onClick={copyPromoCode}>
                {promoCode}
                {showTooltip && <Tooltip>Copied!</Tooltip>}
              </StyledPromoCode>
              .{' '}
              <FAQLink target={`_blank`} href={`${WORDPRESS_BASE_URL}/faqs`}>
                See our FAQs
              </FAQLink>
            </MobileBannerText>
          )}
        </StyledBanner>
      )}
      {isStickerVisible && <PromoTab onClick={handleStickerClick}>Get 15% OFF</PromoTab>}
    </>
  );
};

export default PromotionalBanner;
